import React, { useState, useEffect } from "react";
import { bool, string, object, func, any, node, number } from "prop-types";
import Glide from "@glidejs/glide";

import "./exchange-slider.m.css";

const ExchangeSlider = ({
  children,
  carouselName,
  options,
  totalItems,
  showArrows,
  clickType,
  leftArrow,
  rightArrow,
  currentIndex,
  setCurrentIndex,
  updateFlag
}) => {
  if (!children) {
    return null;
  }

  const [currentSlide, setCurrentSlide] = useState(currentIndex);
  const [glidEle, setGlide] = useState(null);

  useEffect(() => {
    setTimeout(() => {
      renderGlideSlider();
    }, 2000);
    return () => {
      if (children && glidEle) {
        glidEle.destroy();
      }
    };
  }, []);

  useEffect(() => {
    if (clickType === "next") {
      nextSlide();
    } else if (clickType === "prev") {
      previousSlide();
    }
  }, [clickType, updateFlag]);

  useEffect(() => {
    if (glidEle) {
      glidEle.go("=" + currentIndex);
    }
  }, [currentIndex]);

  useEffect(() => {
    if (setCurrentIndex) {
      setCurrentIndex(currentSlide);
    }
  }, [currentSlide]);

  const renderGlideSlider = () => {
    if (children) {
      const glidEleTemp = new Glide("." + carouselName, options).mount();

      glidEleTemp.on(["mount.after", "move.after"], () => {
        setCurrentSlide(glidEleTemp.index);
      });

      setGlide(glidEleTemp);
    }
  };

  const renderChildren = () => {
    return children.map((child, index) => {
      return (
        <li key={index} className="glide__slide">
          {child}
        </li>
      );
    });
  };

  const nextSlide = () => glidEle.go(">");

  const previousSlide = () => glidEle.go("<");

  const renderGlideArrows = () => {
    return (
      <div className="glide__arrows">
        <div styleName="left-arrow" className="left-arrow">
          <button disabled={currentSlide === 0} onClick={previousSlide}>
            {leftArrow}
          </button>
        </div>
        <div styleName="right-arrow" className="right-arrow">
          <button disabled={currentSlide >= totalItems - 1} onClick={nextSlide}>
            {rightArrow}
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className={`glide ${carouselName}`}>
      <div className="glide__track" data-glide-el="track">
        <ul className="glide__slides">{renderChildren()}</ul>
      </div>
      {showArrows && totalItems >= 1 && renderGlideArrows()}
    </div>
  );
};

ExchangeSlider.propTypes = {
  carouselName: string,
  children: any,
  options: object,
  totalItems: number,
  showArrows: bool,
  showBullets: bool,
  clickType: string,
  leftArrow: node,
  rightArrow: node,
  currentIndex: number,
  setCurrentIndex: func,
};
ExchangeSlider.displayName = "Carousel";

ExchangeSlider.defaultProps = {
  showArrows: true,
  currentIndex: 0,
};

export default ExchangeSlider;
