import React from "react";
import get from "lodash/get";
import { shape, object, string } from "prop-types";

import { DfpAd } from "../../dfp-ad/index";

import { splitName } from "./utils";

import "./iframe-row.m.css";

const IframeRow = ({ collection: { metadata, name } }) => {
  const iframeSrc = get(metadata, ["iframe-embed-script"], null);
  const { lastWord, title } = splitName(name);

  return iframeSrc ? (
    <div className="container">
      <div styleName="wrapper">
        <div dangerouslySetInnerHTML={{ __html: iframeSrc }} styleName="widget" />
        <div styleName="title">
          {title}
          <div styleName="last-word">{lastWord}</div>
        </div>
        <div styleName="ad-space">
          <DfpAd adtype="FortuneIndia_RHS_2" />
        </div>
      </div>
    </div>
  ) : null;
};

IframeRow.propTypes = {
  collection: shape({
    metadata: object,
    name: string
  })
};

export default IframeRow;
