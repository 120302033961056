import React from "react";
import { wrapCollectionLayout, EagerLoadImages } from "@quintype/components";

import { ThreeColFourStoryWithAd } from "./three-col-four-story-with-ad";
import { ThreeColWithHorizontalCard } from "./three-col-with-horizontal-card";
import { TrendingCardWithSlider } from "./trending-card";
import { OpinionCarousel } from "./opinion-carousel";
import { ContributorCarousel } from "./contributor-carousel";
import { ThreeColGrid } from "./three-col-grid";
import { OneBigThreeRowCard } from "./one-big-three-row-card";
import { RowColMixedGrid } from "./row-col-mixed-grid";
import { NewsLetterBanner } from "./newsletter-banner";
import { SubscriptionBanner } from "./subscription-banner";
import { RankingGrid } from "./ranking-grid";
import IframeRow from "./iframe-row";

function wrapEager(f) {
  return function WrapEager(props) {
    if (props.index === 0) {
      return (
        <EagerLoadImages predicate={token => token === "above-fold"}>{React.createElement(f, props)}</EagerLoadImages>
      );
    } else {
      return React.createElement(f, props);
    }
  };
}

export default {
  ThreeColGrid: wrapEager(wrapCollectionLayout(ThreeColGrid)),
  OneBigThreeRowCard: wrapEager(wrapCollectionLayout(OneBigThreeRowCard)),
  RowColMixedGrid: wrapEager(wrapCollectionLayout(RowColMixedGrid)),
  ThreeColFourStoryWithAd: wrapEager(wrapCollectionLayout(ThreeColFourStoryWithAd)),
  ThreeColWithHorizontalCard: wrapEager(wrapCollectionLayout(ThreeColWithHorizontalCard)),
  NewsLetterBanner: wrapEager(wrapCollectionLayout(NewsLetterBanner)),
  SubscriptionBanner: wrapEager(wrapCollectionLayout(SubscriptionBanner)),
  RankingGrid: wrapEager(wrapCollectionLayout(RankingGrid)),
  TrendingCardWithSlider: wrapEager(wrapCollectionLayout(TrendingCardWithSlider)),
  OpinionCarousel: wrapEager(wrapCollectionLayout(OpinionCarousel)),
  ContributorCarousel: wrapEager(wrapCollectionLayout(ContributorCarousel)),
  IframeRow: wrapEager(wrapCollectionLayout(IframeRow)),
  defaultTemplate: wrapEager(wrapCollectionLayout(OneBigThreeRowCard))
};
