import React from "react";
import PT from "prop-types";
import { getCollectionName } from "../../helper/utils";
import { Ranking, CollectionName } from "../../atoms";
import "./ranking-grid.m.css";

export const RankingGrid = ({ collection }) => {
  return (
    <div className="container">
      <CollectionName name={getCollectionName(collection)} />
      <div styleName="wrapper">
        {collection.items.map((item, index) => (
          <Ranking key={index} entity={item} />
        ))}
      </div>
    </div>
  );
};

RankingGrid.propTypes = {
  collection: PT.object.isRequired
};
