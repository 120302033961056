import React from "react";
import PT from "prop-types";
import { CollectionName } from "../../atoms";
import {
  StoryCardWithoutImage,
  HorizontalCardWithImage4x3,
  StoryCardWithImage,
  HorizontalStoryCard
} from "../../molecules";
import { getCollectionName } from "../../helper/utils";
import "./row-col-mixed-grid.m.css";
import { DfpAd } from "../../dfp-ad/index";

export const RowColMixedGrid = ({ collection }) => {
  const [firstStory, secondStory, thirdStory, fourthStory, ...restOftheStories] = collection.items.filter(
    item => item.type === "story"
  );

  return (
    <div className="container" styleName="container">
      <CollectionName name={getCollectionName(collection)} />
      <div styleName="wrapper">
        {/* Row 1 */}
        <div styleName="row1">
          {/* Row 1 & Col 1 */}
          <div styleName="row1-col1">
            {firstStory && (
              <div styleName="row1-col1-row1">
                <StoryCardWithoutImage story={firstStory.story} lines={2} />
              </div>
            )}

            {secondStory && (
              <div styleName="row1-col1-row2">
                <HorizontalStoryCard story={secondStory.story} isSubHeadline={false} lines={3} />
              </div>
            )}
          </div>

          {/* Row 1 & Col 2 */}
          {thirdStory && (
            <div styleName="row1-col2">
              <StoryCardWithImage story={thirdStory.story} lines={2} />
            </div>
          )}
        </div>

        {/* Row 2 */}
        {fourthStory && (
          <div styleName="row2">
            <div styleName="row2-col">
              <StoryCardWithImage story={fourthStory.story} lines={2} />
            </div>

            {/* Row 2 & Col 2 with Advertisement */}
            <div styleName="row2-col-advertisement">
              <DfpAd adtype="FortuneIndia_Mid_2" />
            </div>

            {restOftheStories &&
              restOftheStories.length && (
                <div styleName="row2-col">
                  {restOftheStories.slice(0, 3).map((item, index) => {
                    return <HorizontalCardWithImage4x3 story={item.story} key={index} lines={3} />;
                  })}
                </div>
              )}
          </div>
        )}
      </div>
    </div>
  );
};

RowColMixedGrid.propTypes = {
  collection: PT.object.isRequired
};
