import React, { useState } from "react";
import PT from "prop-types";
import get from "lodash/get";
import { Button, TextField, Headline } from "../../atoms";
import { isValidEmail } from "../../helper/utils";
import "./newsletter-banner.m.css";
import { subscribeToMailchimp } from "./api.js";

export function NewsLetterBanner({ collection }) {
  const [email, setEmail] = useState();
  const [error, setError] = useState();
  const [subscribeMailStatus, setSubscribeMailStatus] = useState();

  const onChange = e => {
    setEmail(e.target.value);

    e.target.value ? setError("") : setError("Please provide Email !!");
  };

  const onSubmitClick = () => {
    if (email) {
      if (!isValidEmail(email)) {
        setError("Invalid Email !!");
        // In case if previous success/error messages are set -> remove it
        setSubscribeMailStatus(null);
      } else {
        subscribeToMailchimp({ email: email })
          .then(status => {
            if (status === "subscribed") {
              setSubscribeMailStatus("success");
              setEmail(null);
              setError(null);
            } else {
              setSubscribeMailStatus("failure");
            }
          })
          .catch(() => {
            setSubscribeMailStatus("failure");
          });
      }
    } else {
      setError("Please provide Email !!");
      // In case if previous success/error messages are set -> remove it
      setSubscribeMailStatus(null);
    }
  };

  const imageUrl = get(collection, ["metadata", "bottom-story-page-background-image-url"], "");
  const title = get(collection, ["metadata", "bottom-story-page-title"], "");
  const summary = get(collection, ["metadata", "bottom-story-page-summary"], "");

  return (
    <div className="container">
      <div styleName="wrapper">
        <div styleName="background-image-view">
          <div styleName="image-container">
            <img src={imageUrl} alt={title} styleName="image-style" />
          </div>
          <div styleName="content">
            <div styleName="title-style">
              <Headline value={title} fontSize="halfjumbo" />
            </div>
            <div styleName="subtitle-style">
              <Headline value={summary} fontSize="larger" />
            </div>
          </div>
        </div>
        <div styleName="subscribe-view-wrapper">
          <div styleName="subscribe-view-content">
            <TextField value={email} error={error} onChange={onChange} placeholder="Type your email" />
            <Button content="Subscribe" onClick={onSubmitClick} />
            <span styleName="free-service">*Free Service</span>
            {subscribeMailStatus === "success" && <div styleName="text">Thank you for subscribing!</div>}
            {subscribeMailStatus === "failure" && (
              <div styleName="error-text">Something went wrong. Please try again.</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

NewsLetterBanner.propTypes = {
  collection: PT.object.isRequired
};
