/* eslint-disable jsx-a11y/img-redundant-alt, import/extensions */
import React from "react";
import { object, shape } from "prop-types";
import { DfpAd } from "../dfp-ad/index";
import { getCollectionTemplate } from "../get-collection-template";
import { LazyCollection, WithPreview, replaceAllStoriesInCollection, LazyLoadImages } from "@quintype/components";
import { GoToTop } from "../collection-templates/go-to-top-icon";

export const HomePage = props => (
  <div>
    <div className="container">
      <div className="dfp-ad">
        <DfpAd adtype="FortuneIndia_Mid_1" />
      </div>
    </div>

    <LazyLoadImages>
      <LazyCollection collection={props.data.collection} collectionTemplates={getCollectionTemplate} lazyAfter={2} />
    </LazyLoadImages>
    <GoToTop />
  </div>
);

HomePage.propTypes = {
  data: shape({
    collection: object
  })
};

export const HomePagePreview = WithPreview(HomePage, (data, story) =>
  Object.assign({}, data, {
    collection: replaceAllStoriesInCollection(data.collection, story)
  })
);
