import React, { useState } from "react";
import get from "lodash/get";

import { SectionName } from "../../atoms/section-name";
import LeftArrow from "../../atoms/icon/left-arrow";
import RightArrow from "../../atoms/icon/right-arrow";
import { ResponsiveImage } from "@quintype/components";

import ExchangeSlider from "./exchange-slider";

import "./exchange.m.css";

const SliderBlock = ({ name = "exchange", items }) => {
  if (!items.length) return null;

  const [clickType, setClickEvent] = useState();
  const [updateFlag, setUpdateFlag] = useState(false);

  const handleClick = value => () => {
    setClickEvent(value);
    setUpdateFlag(!updateFlag);
  };

  return (
    <div styleName="slider-block">
      <ExchangeSlider
        carouselName={name}
        options={{
          type: "slider",
          perView: 5,
          perTouch: 1,
          gap: 32,
          breakpoints: {
            810: {
              perView: 2.2
            },
            500: {
              perView: 1.2
            }
          }
        }}
        totalItems={items.length}
        showArrows={false}
        clickType={clickType}
        updateFlag={updateFlag}
      >
        {items.map(i => {
          const pdfUrl = get(i, ["metadata", "pdf-src-key", "pdf-file-url"], "");
          const pdfLink = get(i, ["summary"], pdfUrl);
          const imgUrl = get(i, ["metadata", "cover-image", "cover-image-s3-key"], "");
          // const metadata = get(i, ["metadata", "cover-image", "cover-image-s3-key", "metadata"], "");

          return (
            <div>
              <a href={pdfLink} target="_blank" styleName="card">
                <figure styleName="qt-image-3x4">
                  <ResponsiveImage
                    slug={imgUrl}
                    metadata
                    aspectRatio={[3, 4]}
                    defaultWidth={250}
                    widths={[250, 480, 764]}
                    sizes="( max-width: 500px ) 98%, ( max-width: 768px ) 48%, 23%"
                    imgParams={{ auto: ["format", "compress"] }}
                    styleName="image-wrapper"
                    className="image-card-3x4"
                  />
                </figure>
                <span styleName="card-title">{i.name}</span>
              </a>
            </div>
          );
        })}
      </ExchangeSlider>
      <div styleName="arrows">
        <div styleName="arrow" onClick={handleClick("prev")}>
          <LeftArrow />
        </div>
        <div styleName="arrow" onClick={handleClick("next")}>
          <RightArrow />
        </div>
      </div>
    </div>
  );
};

const FortuneIndiaExchange = ({ data }) => {
  const items = get(data, ["collection", "items"]);

  return (
    <div styleName="wrapper" className="container">
      <div styleName="header-block">
        <div styleName="empty-box" />
      </div>
      <div styleName="title-block">
        <SectionName name="Fortune India Exchange" />
        <div styleName="page-content">
          Fortune India Exchange is all about Bespoke content which is sponsored content in Advertorials, Booklets,
          Videos formats. An ideal platform to co-curate and establish synergies for corporate / brand communication
          objectives.
        </div>
      </div>
      <div styleName="booklet-block">
        <SectionName name="Fortune Exchange Booklet" />
        <SliderBlock name="booklet-block" items={items} />
      </div>
      <div styleName="booklet-block">
        <SectionName name="Fortune Exchange Videos" />
        <SliderBlock name="video-block" items={[]} />

       <div className="row video_div" styleName="video_div">
          <div className="col_new" styleName="col_new" id="source_five">
              <video autoplay controls className="video_ele"><source id="source_video" src="https://openxcell-development-public.s3.ap-south-1.amazonaws.com/fortune-india/5.mp4" type="video/mp4"/>
                Your browser doesn't support HTML5 video tag.</video>
              <div>
                  <div styleName="video-title" >Women Power</div>
                  <div styleName="video-desc">Top women Entrepreneurs 2023 by Digi Live</div>
              </div>
           </div>
           <div className="col_new" id="source_four" styleName="col_new">
               <video autoPlay controls className="video_ele" styleName="thumbnail" poster="https://openxcell-development-public.s3.ap-south-1.amazonaws.com/fortune-india/4_thumbnail.png">
                   <source id="source_video"
                           src="https://openxcell-development-public.s3.ap-south-1.amazonaws.com/fortune-india/4.mp4"
                           type="video/mp4"/>
                   Your browser doesn't support HTML5 video tag.
               </video>
               <div>
                   <div styleName="video-title" >Broadcast1's Business Evangelist of India | AGS Health</div>
                   <div styleName="video-desc">Technology and Innovation has rapidly emerged to be a core component of modern day civilisation. AGS Health and its key stakeholders speak about how they are at the epicentre of innovation in technology through their services.</div>
               </div>
           </div>
          <div className="col_new" styleName="col_new" id="source_one">
              <video autoplay controls className="video_ele"><source id="source_video" src="https://openxcell-development-public.s3.ap-south-1.amazonaws.com/fortune-india/1.mp4" type="video/mp4"/>
                Your browser doesn't support HTML5 video tag.</video>
              <div>
                  <div styleName="video-title" >Microsoft Future Ready</div>
                  <div styleName="video-desc">Shaping a human-powered Growth N Chandrasekaran Chairman Tatasons</div>
              </div>
          </div>
          <div className="col_new" id="source_two" styleName="col_new">
              <video autoplay controls className="video_ele"><source id="source_video" src="https://openxcell-development-public.s3.ap-south-1.amazonaws.com/fortune-india/2.mp4" type="video/mp4"/>
                Your browser doesn't support HTML5 video tag.</video>
              <div>
                  <div styleName="video-title" >Microsoft Future Ready</div>
                  <div styleName="video-desc">Shaping a human-powered Growth Rishad Premji Chairman Wipro Ltd.</div>
              </div>
          </div>
          <div className="col_new" id="source_three" styleName="col_new">
              <video autoplay controls className="video_ele"><source id="source_video" src="https://openxcell-development-public.s3.ap-south-1.amazonaws.com/fortune-india/3.mp4" type="video/mp4"/>
                Your browser doesn't support HTML5 video tag.</video>
              <div>
                  <div styleName="video-title" >Microsoft Future Ready</div>
                  <div styleName="video-desc">Shaping a human-powered Growth Sanjeev Bajaj Chairman & MD Bajaj Finserv Ltd.</div>
              </div>
          </div>

        </div>

      </div>
    </div>
  );
};

export default FortuneIndiaExchange;
