export const splitName = name => {
  let title = "";
  let lastWord = "";

  if (name) {
    const n = name.trim().split(" ");
    lastWord = n[n.length - 1] || null;

    for (let i = 0; i < n.length - 1; i++) {
      title += `${n[i]} `;
    }
  }

  return { title, lastWord };
};
