import React from "react";
import PT from "prop-types";
import { Slider, StoryTileWithContent } from "../../molecules";
import { CollectionName } from "../../atoms";
import { getCollectionName } from "../../helper/utils";
import "./trending-news.m.css";

export const TrendingCardWithSlider = ({ collection }) => {
  const filteredStories = collection.items.filter(item => item.type === "story");

  return (
    <div styleName="wrapper">
      <div className="container">
        <CollectionName name={getCollectionName(collection)} />
        <div styleName="carousel-wrapper">
          <Slider options={{ type: "carousel", perView: 1, autoplay: 5000 }} carouselName={`carousel-${collection.id}`}>
            {filteredStories.length &&
              filteredStories.map((item, index) => <StoryTileWithContent key={index} story={item.story} />)}
          </Slider>
        </div>
      </div>
    </div>
  );
};

TrendingCardWithSlider.propTypes = {
  collection: PT.object.isRequired
};
