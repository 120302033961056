import React from "react";
import PT from "prop-types";
import { MultimediaTile } from "../../molecules";
import { CollectionName } from "../../atoms";
import { getCollectionName } from "../../helper/utils";
import "./one-big-three-row-card.m.css";

export const OneBigThreeRowCard = ({ collection }) => {
  const [firstStory, ...restOftheStories] = collection.items.filter(item => item.type === "story");

  return (
    <div styleName="wrapper">
      <div className="container">
        <CollectionName name={getCollectionName(collection)} />
        <div styleName="stories-wrapper">
          <MultimediaTile story={firstStory.story} type="large" lines={4} />
          <div styleName="stories-grid-wrapper" className="grid-stories-list">
            {restOftheStories.map((item, index) => (
              <MultimediaTile key={index} story={item.story} type="small" lines={2} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

OneBigThreeRowCard.propTypes = {
  collection: PT.object.isRequired
};

export default OneBigThreeRowCard;
