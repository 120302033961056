import React from "react";
import PT from "prop-types";
import { Slider, StoryTileWithAuthorImage } from "../../molecules";
import { CollectionName } from "../../atoms";
import { getCollectionName } from "../../helper/utils";
import "./opinion-carousel.m.css";

export const OpinionCarousel = ({ collection }) => {
  const filteredStories = collection.items.filter(item => item.type === "story");

  return (
    <div styleName="wrapper">
      <div className="container">
        <CollectionName name={getCollectionName(collection)} />
        <div styleName="carousel-wrapper">
          <Slider
            options={{
              type: "carousel",
              autoplay: 3000,
              gap: 20,
              perView: 2,
              breakpoints: {
                768: {
                  perView: 1.2
                }
              }
            }}
            carouselName={`carousel-${collection.id}`}
          >
            {filteredStories.length &&
              filteredStories.map((item, index) => (
                <StoryTileWithAuthorImage key={index} story={item.story} headlineCount={2} summaryLineCount={2} />
              ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

OpinionCarousel.propTypes = {
  collection: PT.object.isRequired
};
