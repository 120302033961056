import React, { useEffect } from "react";
import throttle from "lodash/throttle";
import { GoTopIcon } from "../../atoms/icon";
import "./go-to-top-icon.m.css";

export const GoToTop = () => {
  const onClick = e => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  const handleScroll = e => {
    if (document.body.scrollTop > 400 || document.documentElement.scrollTop > 400) {
      document.getElementById("go-to-top-wrapper").style.display = "block";
    } else {
      document.getElementById("go-to-top-wrapper").style.display = "none";
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", throttle(handleScroll, 100));
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  return (
    <div id="go-to-top-wrapper" styleName="wrapper" onClick={onClick} title="go to top">
      <GoTopIcon height={50} width={50} />
    </div>
  );
};
