import React from "react";
import { createDfpAdComponent } from "@quintype/components";
import get from "lodash/get";

const ViewPortSizeMappingHorizontal728to90 = [
  { viewport: [900, 90], sizes: [[728, 90]] },
  { viewport: [320, 0], sizes: [[300, 250]] },
  { viewport: [0, 0], sizes: [[300, 250]] }
];

const ViewPortSizeMappingHorizontal728x50 = [
  { viewport: [900, 90], sizes: [[728, 90]] },
  { viewport: [320, 0], sizes: [[320, 50]] },
];

const ViewPortSizeMappingVertical970to250 = [
  { viewport: [900, 90], sizes: [[970, 250], [970, 90], [728, 90]] },
  { viewport: [0, 0], sizes: [[320, 100]] }
];
const ViewPortSizeMappingVertical780to320 = [
  { viewport: [320, 0], sizes: [[336, 280], [300, 250]] },
  { viewport: [0, 0], sizes: [[300, 250]] }
];

const ViewPortSizeMappingVertical300to250 = [
  { viewport: [900, 90], sizes: [[336, 280], [300, 250]] },
  { viewport: [0, 0], sizes: [[336, 280], [300, 250]] }
];

const ViewPortSizeMappingVertical728to250 = [
  { viewport: [900, 90], sizes: [[728, 90]] },
  { viewport: [0, 0], sizes: [[300, 50],[300, 250]] }
];

const CONFIG = {
  FortuneIndia_Top: {
    adUnit: "RPSG_Fortune_Desktop/RPSG_FRTN_HOM_ATF_728",
    sizes: [[728, 90], [300, 250]],
    viewPortSizeMapping: ViewPortSizeMappingHorizontal728to90
  },
  FortuneIndia_Mid_1: {
    adUnit: "RPSG_Fortune_Desktop/RPSG_FRTN_HOM_Leaderboard",
    sizes: [[970, 250], [970, 90], [728, 90], [320, 100]],
    viewPortSizeMapping: ViewPortSizeMappingVertical970to250
  },
  FortuneIndia_Mid_3: {
    adUnit: "RPSG_Fortune_Desktop/RPSG_FRTN_HOM_BTF_728",
    sizes: [[300, 250], [728, 90]],
    viewPortSizeMapping: ViewPortSizeMappingVertical728to250
  },
  FortuneIndia_Mid_2: {
    adUnit: "RPSG_Fortune_Mob/RPSG_FRTN_HP_Mob_mREC",
    sizes: [[336, 280], [300, 250]],
    viewPortSizeMapping: ViewPortSizeMappingVertical780to320
  },
  FortuneIndia_RHS_1: {
    adUnit: "RPSG_Fortune_Desktop/RPSG_FRTN_HOM_mREC",
    sizes: [[336, 280], [300, 250]],
    viewPortSizeMapping: ViewPortSizeMappingVertical300to250
  },
  FortuneIndia_RHS_2: {
    adUnit: "FortuneIndia_RHS_2",
    sizes: [[300, 250], [320, 50]],
    viewPortSizeMapping: ViewPortSizeMappingVertical300to250
  },
  FortuneIndia_Bottom: {
    adUnit: "FortuneIndia_Bottom",
    sizes: [[728, 90], [320, 50]],
    viewPortSizeMapping: ViewPortSizeMappingHorizontal728x50
  }
};

const DfpAdContent = createDfpAdComponent({
  defaultNetworkID: "23172162065",
  config: CONFIG,
  targeting: function(state) {
    const params = {};
    if (
      get(state, ["qt", "pageType"]) === "story-page" &&
      get(state, ["qt", "data", "story", "metadata", "sponsored-by"])
    ) {
      params.sponsor = get(state, ["qt", "data", "story", "metadata", "sponsored-by"]);
      return params.sponsor;
    }
    return params;
  }
});
export const DfpAd = props => {
  return (
    <div>
      <DfpAdContent {...props} />
    </div>
  );
};
