import React from "react";

import FortuneIndiaExchange from "../molecules/exchange";

const FortuneIndiaExchangePage = props => (
  <div>
    <FortuneIndiaExchange data={props.data} />
  </div>
);

export { FortuneIndiaExchangePage };
