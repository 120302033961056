import React, { useState } from "react";
import PT from "prop-types";
import { ImageHeadlineByName } from "../../molecules";
import { CollectionName, ToggleButton } from "../../atoms";
import { getCollectionName } from "../../helper/utils";
import "./three-col-grid.m.css";

export function ThreeColGrid({ collection }) {
  const [isOpen, setIsOpen] = useState(true);
  const list = collection.items.filter(item => item.type === "story").slice(0, 3);

  return (
    <div className="container">
      <div styleName="wrapper">
        <div styleName="header-wrapper">
          <CollectionName name={getCollectionName(collection)} />
          <ToggleButton toggleClick={() => setIsOpen(!isOpen)} isOpen={isOpen} />
        </div>
        <div styleName={isOpen ? "stories" : "stories-hide"}>
          {list.map((item, index) => (
            <ImageHeadlineByName key={index} story={item.story} lines={3} />
          ))}
        </div>
      </div>
    </div>
  );
}

ThreeColGrid.propTypes = {
  collection: PT.object.isRequired
};
