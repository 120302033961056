import React from "react";

const RightArrow = () => (
  <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.791748 1.46772L1.83099 0.471741L7.14587 7.25826L1.82944 14L0.793279 13.0001L5.32271 7.25455L0.791748 1.46772Z" fill="white" />
  </svg>

)

export default RightArrow;
